<template>
    <div class="chroma">
      <section class="section px-5 py-3">
        <!-- TABS (solo admin) -->
        <b-tabs v-model="activeTab" :class="{'no-labels': !ShowAdminUi}" expanded>
            <b-tab-item :label="$t('exp_textbox.send')">
              <p class="has-text-weight-bold mb-3">{{ options.controller.callToAction || '' }}</p>
              <b-field v-if="options.blockType == 'questionsBox'">
            <b-select v-if="options.categories?.length > 0" :placeholder="options.controller.categoryPlaceholder || $t('exp_textbox.category')" expanded v-model="questionCategory">
                <option v-for="(e,i) in options.categories" :key="i" :value="e.name">{{e .name }}</option>
            </b-select>
            </b-field>
            <b-field class="mb-0">
                <b-input maxlength="140" type="textarea" v-model="questionText" :placeholder="options.controller.placeholder || ''"></b-input>
            </b-field>
            <div class="send-buttons">
              <b-button
              :label="options.controller.sendButtonLabel || $t('exp_textbox.send')"
              type="is-primary"
              expanded
              :disabled="!allowedToSubmitQuestion"
              @click="SendQuestion()"/>
            <b-button
              v-if="options.blockType == 'questionsBox' && ShowAdminUi"
              :label="$t('exp_textbox.sendRandom')"
              expanded
              type="is-info"
              @click="SendRandom()"/>
            </div>
            
            </b-tab-item>
            <b-tab-item v-if="ShowAdminUi" :label="$t('exp_textbox.moderate')">
              <!-- opciones especificas de la nube de palabras -->
              <b-dropdown aria-role="list" class="mb-4" v-if="options.blockType == 'wordcloud'">

              <template #trigger="{ }" v-if="options.intro.standByVideos?.length == 0">
                <b-button type="is-primary" expanded>
                  Word Cloud Actions <d-icon icon="FaPlusCircle"/>
                </b-button>
              </template>
              <b-dropdown-item aria-role="listitem" @click="CleanWordCloud()">{{ $t('exp_textbox.cleanWords') }}</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="RestartWordCloud()">{{ $t('exp_textbox.restartAnimation') }}</b-dropdown-item>
            </b-dropdown>

              <!-- filtro especifico de envio de preguntas -->
              <div v-if="options.blockType == 'questionsBox'" class="questions-tools">
                <div class="columns is-mobile mb-0">
                  <div v-if="options.categories?.length > 0" class="column">
                    <b-field>
                      <b-select
                        :placeholder="options.controller.categoryPlaceholder || $t('exp_textbox.category')"
                        v-model="filteredCategory"
                        size="is-small"
                        expanded>
                          <option value="all">{{ $t('exp_textbox.all') }}</option>
                          <option v-for="(e,i) in options.categories" :key="i" :value="e.name">{{ e.name }}</option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-button
                      :label="$t('exp_textbox.removeCurrent')"
                      @click="QuitarPreguntaActual()"
                      type="is-danger"
                      size="is-small"
                      expanded/>
                  </div>
                </div>
              </div>

              <b-table
                :data="moderationEntriesToShow"
                :striped="true"
                :hoverable="true"
                :mobile-cards="true"
                :paginated="moderationEntriesToShow.length>12"
                :per-page="12"
                :current-page.sync="currentPage"
                :row-class="(row, index) => row.shownOnScreen && 'shownOnScreen'"
              >

                <b-table-column field="text" label="Reaction" v-slot="props">
                  {{ props.row.reaction }}
                </b-table-column>

                <b-table-column field="username" label="User" class="td-user" v-slot="props">
                  <div class="td-user">{{ props.row.user.username }}</div>
                </b-table-column>

                <!-- <b-table-column field="email" label="Email" v-slot="props">
                  {{ props.row.user.email }}
                </b-table-column> -->

                <b-table-column field="sent" label="Sent" v-slot="props">
                  <div class="td-sent">{{ timeAgo(props.row.createdAt) }}</div>
                </b-table-column>

                <b-table-column field="actions" v-slot="props">
                  <div class="actions">
                    <b-button type="is-danger" size="is-small" expanded @click="DeleteReaction(props.row)"><d-icon icon="FaTrash" size="is-small"/></b-button>
                    <b-button type="is-success" size="is-small" expanded @click="EditBeforeSend(props.row)"><d-icon icon="FaShare" size="is-small"/></b-button>
                  </div>
                </b-table-column>

                <template #empty>
                  <div class="has-text-centered">{{ $t('exp_textbox.noResults') }}</div>
                </template>
              </b-table>
            </b-tab-item>
        </b-tabs>
      </section>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { hsv2hex } from "color-functions";
  const whitelabelConfig = require('@/whitelabel.config');
  import { formatTimeAgo } from "../utils";

  export default {
    components: {},
    props: {
    options: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        questionText: "",
        questionCategory: null,
        filteredCategory: null,
        moderationEntries: [],
        currentPage: 1,
        activeTab: 0
      };
    },
    computed: {
      allowedToSubmitQuestion(){
        if(this.questionText.length < 4) return false  // Minimo 4 caracteres

        if(this.options.blockType == 'questionsBox' && this.options.categories?.length > 0 && this.questionCategory == null) return false // si tiene el selector de cateogria, no puede estar vacio

        return true;
      },
      moderationEntriesToShow(){
        if(this.filteredCategory == null || this.filteredCategory == "all"){
          return this.moderationEntries.filter(x => !x.hide);
        }
        return this.moderationEntries.filter(x => !x.hide && x.category == this.filteredCategory);
      },  
      ShowAdminUi(){
        return this.$store.getters['user/hasAdminAuthority'] && this.$store.hasModule('admin')
      }
    },
    watch:{
      activeTab(tab){
        if(tab == 1 && this.moderationEntries.length == 0){
          this.RetrieveTextToModerate()
        }
      },
      ShowAdminUi(state){
        if(state){
          setTimeout(() => {
            if(this.moderationEntries.length == 0){
              this.RetrieveTextToModerate()
            }
          }, 2000);
        }
      }
    },
    sockets:{
      SendReaction(data){
        console.log('sendreaction',data)
        if(data.experience !=  this.$store.state.user.experienceid) return
        if(data.type == 'text'){
          data.hide = false
          data.shownOnScreen = false
          this.moderationEntries.unshift(data)
        }
      }
    },
    methods: {
      timeAgo(time){
        return formatTimeAgo(time,this.$i18n.locale)
      },
      QuitarPreguntaActual(){
        this.$socket.client.emit('gameMessage', {
          type: 'QuitarPreguntaActual',
        });
      },
      SendRandom(){
        // Send random fake text reaction
        console.log('sending fake random text reaction')
   
        let hueVal = Math.round(Math.random() * 360)
        let color = hsv2hex(
          hueVal,
          this.$store.getters["user/ColorSaturation"],
          this.$store.getters["user/ColorBrightness"]
        ).substring(1);

        

        let category = this.questionCategory || this.options.categories[Math.round(Math.random() * 99999) % this.options.categories.length]?.name;

        console.log('sending fake random text reaction',category)

        this.$socket.client.emit('gameMessage', {
          type: 'FakeReaction',
          player: {
            username: whitelabelConfig.randomNickname(),
            color: color,
            avatar: Math.floor(Math.random() * (this.$store.state.avatars.length - 2) + 1)
          },
          category: category
        })
      },
      CleanWordCloud(){
        this.$socket.client.emit('gameMessage', {
          type: 'CleanWordCloud',
        });
      },
      RestartWordCloud(){
        this.$socket.client.emit('gameMessage', {
          type: 'RestartWordCloud',
        });
      },
      DeleteReaction(reaction){
        // console.log("Delete", reaction)
        this.$buefy.dialog.confirm({
            title: 'Deleting reaction',
            message: `Are you sure you want to <b>delete</b> this reaction? This action cannot be undone. <br><br> <em>${reaction.reaction}</em>`,
            confirmText: 'Delete Reaction',
            type: 'is-danger',
            onConfirm: async () => {
              this.$store.commit("StartLoading")
              const res = await axios.put(process.env.VUE_APP_API_BASE + "/api/reactions/" + reaction.id, {
                hide: true
              },
              {
                headers: {
                  Authorization: "JWT " + this.$store.state.user.token,
                },
              });
              if (res.status == 200) {
                this.moderationEntries.find(x => x.id == reaction.id).hide = true
                this.$store.commit("StopLoading");
                this.$buefy.toast.open('Reaction deleted!')
              }else {
                this.$buefy.notification.open({
                  type: "is-danger",
                  message: "Error deleting reaction"
              });
            }
          }
        })
      },
      EditBeforeSend(reaction){
        console.log("edit", reaction)
        this.$buefy.dialog.prompt({
            message: 'Check the reaction for typos before sending to screen',
            inputAttrs: {
                type: 'text',
                placeholder: 'My message is...',
                value: reaction.reaction
            },
            confirmText: 'Send',
            trapFocus: true,
            closeOnConfirm: true,
            onConfirm: async (value, {close}) => {
              console.log(value)
              // ENVIO SOCKET A PANTALLA
              this.$socket.client.emit('gameMessage', {
                type: 'ModeratedReaction',
                reaction: value,
                user: reaction.user,
                id: reaction.id,
              });
              // LE DIGO A PAYLOAD QUE LO MARQUE COMO YA ENVIADO
              await axios.put(process.env.VUE_APP_API_BASE + "/api/reactions/" + reaction.id, {
                shownOnScreen: true,
                reaction: value,
              },
              {
                headers: {
                  Authorization: "JWT " + this.$store.state.user.token,
                },
              });
              // MODIFICO LA INSTANCIA LOCAL
              const item = this.moderationEntries.find(x => x.id == reaction.id)
              item.reaction = value
              item.shownOnScreen = true
              close()
              this.$buefy.toast.open('Reaction sent to screen')
            }
        })
      },
      async RetrieveTextToModerate(){
        console.log("Retrieving reactions")
        let query = "/api/reactions"
        query += "?limit=999"
        query += "&where[type][equals]=text" // Solo las reacciones de texto (no emojis)
        // query += `&where[space.slug][equals]=${this.$store.state.user.space}` // Enviadas a este espacio
        query += `&where[experience][equals]=${this.$store.state.user.experienceid}` // enviadas a esta experience
        query += "&where[hide][equals]=false" // Que no estén ocultas
        console.log(this.$store.state.user.experienceid)
        const res = await axios.get(process.env.VUE_APP_API_BASE + query)
        console.log(process.env.VUE_APP_API_BASE + query, this.$store.state.user.experienceid, res)
        this.moderationEntries = res.data.docs
      },
      SendQuestion(){
        // Todo chequear que tenga caracteres minimos
        this.$store.dispatch('user/SendReaction', {reaction: this.questionText, type:'text', category: this.questionCategory})
        this.questionText = ""

        this.$buefy.notification.open({
            type: "is-primary",
            message: this.options.controller.succesNotification || this.options.blockType == 'questionsBox' ? this.$t('exp_textbox.questionSent') : this.$t('exp_textbox.messageSent')
        });

        this.$sfxPlay('positivenotification')

        // Pensar si queremos evitar que spameen? Tipo un cooldown antes de poder enviar
      },
    },
    mounted() {

    },
  };
  </script>

  <style lang="scss">
  .send-buttons {
    display: flex;
    gap: 1em;
  }

  td:last-of-type::before {
      content: unset !important;
  }

  .tab-content {
    padding-inline: 0px !important;
  }
  .no-labels {
    nav {
      display: none;
    }
  }

  .dropdown {
    width: 100%;

    .dropdown-trigger {
      width: 100%;
    }
  }

  .actions {
    display: flex;
  }

  tr.shownOnScreen {
    opacity: .3;
  }

  .pagination-previous>.icon::after{
    content: "<";
  }

  .pagination-next>.icon::after{
    content: ">";
  }

  .questions-tools {

    display: flex;
    justify-content: flex-end;

    .columns {
      max-width: 400px;

      .column {
        display: flex;
        align-items: flex-end;
      }
    }

  }

  .td-user{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    max-width: 85px;
  }

  .td-sent {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: fit-content;
  }
  </style>